<template>
  <div>
    <!-- eslint-disable vue/attribute-hyphenation -->
    <vue-good-table
      ref="vgt"
      :columns="columns"
      :rows="data"
      :pagination-options="{
        enabled: true,
        perPage:filterQuotes.limit
      }"
      styleClass="vgt-table"
    >
      <empty-state-table slot="emptystate" />
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.label ==='id'">
          <span class="text-left">{{ props.column }}</span>
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'project'">
          <router-link :to="{ name: 'project-detail', params: {id: props.row.project.id.toString()} }">
            <h6 class="text-primary m-0">{{ props.row.project.name }}</h6>
          </router-link>
          <b-badge
            v-if="props.row.project.category"
            variant="light-info"
            class="mt-50 mb-25"
          >
            {{ props.row.project.category.name }}
          </b-badge>
          <small
            v-if="props.row.project.tgl_reminder"
            class="d-block"
          >Tanggal reminder : {{ formatDate(props.row.project.tgl_reminder) }}</small>
        </span>
        <span v-else-if="props.column.field === 'status'">
          <router-link :to="{ name: 'quotation-detail', params: {id: props.row.id.toString(), version: props.row.version.toString()} }">
            <h6 class="text-primary">{{ props.row.name }}</h6>
          </router-link>
          <b-badge :variant="statusOptions[props.row.status.id].badge">
            {{ statusOptions[props.row.status.id].label }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'total'">
          <div v-b-popover.hover.top="`Total : ${formatCurrency(props.row.total)} ${permission.viewHpp ? ' • HPP : ' + formatCurrency(props.row.total_hpp) : '' }`">
            <h6 class="m-0 mb-25">Rp. {{ shortNumber(props.row.total) }}</h6>
            <small
              v-if="permission.viewHpp"
              class="text-muted d-block"
            >
              HPP : Rp. {{ shortNumber(props.row.total_hpp) }}
            </small>
          </div>
        </span>
        <span v-else-if="props.column.field === 'created_at'">
          <small class="text-muted">Dibuat : </small>
          <h6 class="m-0">{{ formatDate(props.row.created_at) }} </h6>
          <div
            v-if="props.row.updated_at"
          >
            <small class="text-muted">Diupdate : </small>
            <h6>{{ formatDate(props.row.updated_at) }}</h6>
          </div>
        </span>
        <span v-else-if="props.column.field === 'userCreate'">
          <b-media class="mt-25">
            <template #aside>
              <b-avatar
                size="36"
                :text="avatarText(props.row.userCreate.name)"
                variant="light-primary"
                :src="props.row.userCreate.photo && props.row.userCreate.photo !== 'default.png' ?
                  `${$restHTTP}/assets/img/profile-picture/${props.row.userCreate.id}/${props.row.userCreate.photo}` :
                  `${$restHTTP}/assets/img/profile-picture/default.png`
                "
              />
            </template>
            <router-link :to="{ name: 'user-detail', params: {id: props.row.userCreate.id.toString()} }">
              <h6 class="text-primary m-0">{{ props.row.userCreate.name }}</h6>
            </router-link>
            <small
              v-if="props.row.userCreate.role"
              class="d-block text-muted"
            >
              {{ props.row.userCreate.role.name }}
            </small>
          </b-media>
        </span>
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'quotation-detail', params: {id: props.row.id.toString(), version: props.row.version.toString()} }"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50 mb-25 text-primary"
                />
                <span class="text-primary">
                  Lihat Quote
                </span>
              </b-dropdown-item>
              <b-dropdown-divider v-if="props.row.status.id !== 6 && resolveUserPermission(props.row.userCreate.id)" />
              <b-dropdown-item
                v-if="props.row.status.id === 2 && $root.role === 2 && props.row.userCreate.id !== $store.getters.getCurrentUser.user.id"
                @click="acceptRejectQuote(0, props.row.id)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50 mb-25 text-danger"
                />
                <span class="text-danger">
                  Reject Quote
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.status.id === 3 && resolveUserPermission(props.row.userCreate.id)"
                @click="rejectByCustomer(props.row.id)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50 mb-25 text-danger"
                />
                <span class="text-danger">
                  Reject Quote by Customer
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.status.id >= 1 && props.row.status.id <= 5 && props.row.status.id !== 3 && resolveUserPermission(props.row.userCreate.id)"
                @click="closeQuote(props.row.id)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50 mb-25 text-warning"
                />
                <span class="text-warning">
                  Close Quote
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.status.id === 7 && resolveUserPermission(props.row.userCreate.id)"
                @click="cancelQuote(props.row.id)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50 mb-25 text-warning"
                />
                <span class="text-warning">
                  Cancel Quote
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.status.id === 3 && resolveUserPermission(props.row.userCreate.id)"
                @click="forwardQuote(props.row.id, props.row.version)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50 mb-25 text-info"
                />
                <span class="text-info">
                  Forward Quote
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.status.id === 5 && resolveUserPermission(props.row.userCreate.id)"
                @click="reviseQuote(props.row.id, props.row.version)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50 mb-25 text-info"
                />
                <span class="text-info">
                  Revise Quote
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="(props.row.status.id === 1 || props.row.status.id === 4) && resolveUserPermission(props.row.userCreate.id)"
                @click="editQuote(props.row.id, props.row.version)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50 mb-25 text-info"
                />
                <span class="text-info">
                  Edit Quote
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="(props.row.status.id === 1 || props.row.status.id === 4) && resolveUserPermission(props.row.userCreate.id)"
                @click="submitQuote(props.row.id)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50 mb-25 text-primary"
                />
                <span class="text-primary">
                  Submit Quote
                </span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="props.row.status.id === 2 && $root.role === 2 && props.row.userCreate.id !== $store.getters.getCurrentUser.user.id"
                @click="acceptRejectQuote(1, props.row.id)"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50 mb-25 text-primary"
                />
                <span class="text-primary">
                  Approve Quote
                </span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Lihat 1 hingga
            </span>
            <b-form-select
              v-model="filterQuotes.limit"
              :options="perPageOptions"
              class="mx-1"
            />
            <span class="text-nowrap "> dari total {{ count }} data </span>
          </div>
          <div>
            <b-pagination
              v-model="filterQuotes.offset"
              :total-rows="count"
              :per-page="filterQuotes.limit"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>

import {
  BFormSelect, BPagination, BDropdown, BDropdownItem, BBadge, BMedia, BAvatar, BDropdownDivider, VBPopover,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import { mapGetters } from 'vuex'
import EmptyStateTable from '@/components/misc/EmptyStateTable.vue'
import { avatarText, shortNumber, formatCurrency } from '@core/utils/filter'
import { createHelpers } from 'vuex-map-fields'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const { mapFields } = createHelpers({
  getterType: 'getQuotesField',
  mutationType: 'updateQuotesField',
})

export default {
  components: {
    BFormSelect,
    BPagination,
    BDropdown,
    BDropdownItem,
    BBadge,
    BMedia,
    BAvatar,
    BDropdownDivider,
    VueGoodTable,
    EmptyStateTable,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      avatarText,
      shortNumber,
      formatCurrency,
      currentPage: 1,
      limitPage: 25,
      perPageOptions: [25, 50, 100, 200],
      columns: [
        {
          field: 'status',
          label: 'Quote',
          sortable: false,
          tdClass: 'w-vgt-240',
        },
        {
          field: 'project',
          label: 'Project',
          sortable: false,
          tdClass: 'w-vgt-260',
        },
        {
          field: 'total',
          label: 'Total',
          sortable: false,
          width: '180px',
        },
        {
          field: 'created_at',
          label: 'Tanggal',
          sortable: false,
          width: '120px',
        },
        {
          field: 'userCreate',
          label: 'Dibuat Oleh',
          sortable: false,
          width: '200px',
        },
        {
          label: 'Actions',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapFields([
      'filterQuotes',
    ]),
    ...mapGetters({
      statusOptions: 'getStatusOptions',
      permission: 'getUserPermission',
    }),
  },
  mounted() {
    this.$refs.vgt.currentPage = this.filterQuotes.offset
  },
  methods: {
    formatDate(date) {
      if (!date) {
        return ''
      }
      const parsedDate = Date.parse(date.split('.')[0])
      return moment(parsedDate).format('DD-MM-YYYY')
    },
    submitQuote(id) {
      this.$swal({
        title: 'Submit Quote?',
        text: 'Konfirmasi jika anda ingin submit quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'text-primary btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$store.commit('setLoadingQuotes', true)
          this.$store.dispatch('submitQuote', { id: Number(id) }).then(async response => {
            if (response.data.submitQuote.status.toLowerCase() === 'success') {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil disubmit',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchQuote()
            } else {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.submitQuote.message,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          }).catch(() => {
            this.$store.commit('setLoadingQuotes', false)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
        }
      })
    },
    closeQuote(id) {
      this.$swal({
        title: 'Close Quote?',
        text: 'Konfirmasi jika anda ingin menclose quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'text-warning btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$store.commit('setLoadingQuotes', true)
          await this.$store.dispatch('closeQuote', { id: Number(id) }).then(async response => {
            if (response.data.closeQuote.status.toLowerCase() === 'success') {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil diclose!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchQuote()
            } else {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.submitQuote.message,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          }).catch(() => {
            this.$store.commit('setLoadingQuotes', false)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
        }
      })
    },
    cancelQuote(id) {
      this.$swal({
        title: 'Cancel Quote?',
        text: 'Konfirmasi jika anda ingin mencancel quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'text-warning btn btn-outline-warning ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$store.commit('setLoadingQuotes', true)
          await this.$store.dispatch('closeQuote', { id: Number(id) }).then(async response => {
            if (response.data.closeQuote.status.toLowerCase() === 'success') {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Berhasil dicancel!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchQuote()
            } else {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.submitQuote.message,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          }).catch(() => {
            this.$store.commit('setLoadingQuotes', false)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
        }
      })
    },
    acceptRejectQuote(status, id) {
      this.$swal({
        title: status === 0 ? 'Reject Quote?' : 'Approve Quote?',
        text: status === 0 ? 'Konfirmasi jika anda ingin mereject quote' : 'Konfirmasi jika anda ingin mengapprove quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: status === 0 ? 'btn btn-danger' : 'btn btn-primary',
          cancelButton: status === 0 ? 'text-danger btn btn-outline-danger ml-1' : 'text-primary btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value && status === 0) {
          this.$swal({
            title: 'Alasan Reject',
            input: 'text',
            html: '<p>Beri alasan reject di bawah ini.</p',
            showCancelButton: true,
            confirmButtonText: 'Lanjutkan',
            cancelButtonText: 'Batalkan',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1',
            },
            buttonsStyling: false,
          }).then(async notes => {
            if (notes.isConfirmed) {
              this.$store.commit('setLoadingQuotes', true)
              await this.$store.dispatch('acceptRejectQuote', {
                id: Number(id),
                status,
                notes: notes.value,
              }).then(async response => {
                if (response.data.acceptRejectQuote.status.toLowerCase() === 'success') {
                  this.$store.commit('setLoadingQuotes', false)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: status === 0 ? 'Berhasil direject!' : 'Berhasil diapprove!',
                      icon: 'EditIcon',
                      variant: 'success',
                    },
                  })
                  this.fetchQuote()
                } else {
                  this.$store.commit('setLoadingQuotes', false)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.submitQuote.message,
                      icon: 'EditIcon',
                      variant: 'warning',
                    },
                  })
                }
              }).catch(() => {
                this.$store.commit('setLoadingQuotes', false)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Terjadi kesalahan',
                    icon: 'EditIcon',
                    variant: 'warning',
                  },
                })
              })
            }
          })
        } else if (result.value && status === 1) {
          this.$store.commit('setLoadingQuotes', true)
          await this.$store.dispatch('acceptRejectQuote', {
            id: Number(id),
            status,
            notes: '',
          }).then(async response => {
            if (response.data.acceptRejectQuote.status.toLowerCase() === 'success') {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: status === 0 ? 'Berhasil direject!' : 'Berhasil diapprove!',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
              this.fetchQuote()
            } else {
              this.$store.commit('setLoadingQuotes', false)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.submitQuote.message,
                  icon: 'EditIcon',
                  variant: 'warning',
                },
              })
            }
          }).catch(() => {
            this.$store.commit('setLoadingQuotes', false)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Terjadi kesalahan',
                icon: 'EditIcon',
                variant: 'warning',
              },
            })
          })
        }
      })
    },
    rejectByCustomer(id) {
      this.$swal({
        title: 'Reject Quote?',
        text: 'Konfirmasi jika anda ingin mereject quote',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async result => {
        if (result.value) {
          this.$swal({
            title: 'Alasan Reject',
            input: 'text',
            html: '<p>Beri alasan reject di bawah ini.</p',
            showCancelButton: true,
            confirmButtonText: 'Lanjutkan',
            cancelButtonText: 'Batalkan',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1',
            },
            buttonsStyling: false,
          }).then(async notes => {
            if (notes.isConfirmed) {
              this.$store.dispatch('rejectQuoteByCustomer', { id: Number(id), notes: notes.value }).then(response => {
                if (response.data.rejectQuoteByCustomer.status.toLowerCase() === 'success') {
                  this.$store.commit('setLoadingQuotes', false)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Berhasil direject!',
                      icon: 'EditIcon',
                      variant: 'success',
                    },
                  })
                  this.fetchQuote()
                } else {
                  this.$store.commit('setLoadingQuotes', false)
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: response.data.rejectQuoteByCustomer.message,
                      icon: 'EditIcon',
                      variant: 'warning',
                    },
                  })
                }
              }).catch(() => {
                this.$store.commit('setLoadingQuotes', false)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: 'Terjadi kesalahan',
                    icon: 'EditIcon',
                    variant: 'warning',
                  },
                })
              })
            }
          })
        }
      })
    },
    reviseQuote(id, version) {
      this.$router.push({
        name: 'revisequote',
        params: {
          id: id.toString(),
          version: version.toString(),
        },
      })
    },
    editQuote(id, version) {
      this.$router.push({
        name: 'editquote',
        params: {
          id: id.toString(),
          version: version.toString(),
        },
      })
    },
    forwardQuote(id, version) {
      this.$router.push({
        name: 'quotation-detail',
        params: {
          id: id.toString(),
          version: version.toString(),
          openForwardQuote: true,
        },
      })
    },
    fetchQuote() {
      this.$emit('refetch')
    },
    resolveUserPermission(idUser) {
      return (this.$root.role === 2 || idUser === this.$store.getters.getCurrentUser.user.id)
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
