<template>
  <div>
    <div
      v-if="loading !== 'error'"
    >
      <div
        v-if="loading"
        key="1"
        class="d-flex align-items-center justify-content-center"
        style="height: 80vh"
      >
        <b-spinner variant="primary" />
      </div>
      <div
        v-else
        key="2"
      >
        <back-navigation title="Detail Project" />
        <XyzTransition
          appear
          xyz="fade left-3 delay-5"
        >
          <div>
            <b-card>
              <b-row>
                <b-col
                  cols="12"
                  lg="4"
                  class="mb-1 mb-lg-0"
                >
                  <h4>
                    {{ projectData.name }}
                  </h4>
                  <b-badge
                    v-if="projectData.category"
                    variant="primary"
                    class="mr-50"
                  >
                    {{ projectData.category.name }}
                  </b-badge>
                  <b-badge
                    :variant="projectData.status === 0 ? 'secondary' : 'primary'"
                    class="mr-50"
                  >
                    {{ projectData.status === 0 ? 'Tidak Aktif' : 'Aktif' }}
                  </b-badge>
                  <br>
                  <div class="d-flex align-items-center mt-1">
                    <b-avatar
                      class="mr-50"
                      variant="light-primary"
                      rounded
                      size="3rem"
                    >
                      <feather-icon
                        icon="CalendarIcon"
                        size="20"
                      />
                    </b-avatar>
                    <div>
                      <small class="text-muted">
                        Tanggal Reminder
                      </small>
                      <h6 class="m-0 mb-25 mt-25">
                        {{ formatDate(projectData.tgl_reminder) }}
                      </h6>
                    </div>
                  </div>
                  <div class="d-flex flex-wrap mt-1 mb-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      :disabled="!projectData.isAssigned && !permission.projectEdit "
                      @click="projectData.isAssigned || permission.projectEdit? $router.push({ name: 'project-edit', params: { id: id }}) : $router.push('/unauthorized')"
                    >
                      Edit
                    </b-button>
                    <b-button
                      v-if="projectData.status"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="ml-1"
                      :disabled="!projectData.isAssigned && !permission.projectDelete "
                      @click="projectData.isAssigned || permission.projectDelete ? deleteProjectConfirm() : $router.push('/unauthorized')"
                    >
                      Delete
                    </b-button>
                    <b-button
                      v-else
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="outline-danger"
                      class="ml-1"
                      :disabled="!projectData.isAssigned && !permission.projectDelete "
                      @click="projectData.isAssigned || permission.projectDelete ? confirmRestore() : $router.push('/unauthorized')"
                    >
                      Restore
                    </b-button>
                  </div>
                  <small class="text-muted">
                    Customer
                  </small>
                  <div class="d-flex align-items-center mt-50">
                    <b-avatar
                      class="mr-1"
                      :text="avatarText(projectData.customer.name)"
                      variant="light-primary"
                      rounded
                      size="3.6rem"
                    />
                    <div>
                      <router-link :to="{ name: 'customer-detail', params: {id: projectData.customer.id} }">
                        <h5 class="m-0 text-primary cursor-pointer">
                          {{ projectData.customer.name }}
                        </h5>
                      </router-link>
                      <small class="text-muted d-block">
                        {{ projectData.customer.customerNo }}
                      </small>
                      <b-badge
                        v-if="projectData.customer.category"
                        v-b-popover.hover.top="'Kategori'"
                        variant="light-primary"
                        class="mr-50"
                      >
                        {{ projectData.customer.category.name }}
                      </b-badge>
                      <b-badge
                        v-b-popover.hover.top="'Kategori Harga'"
                        variant="light-info"
                      >
                        {{ projectData.customer.priceCategory.name }}
                      </b-badge>
                    </div>
                  </div>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                  class="mb-1 mb-lg-0"
                >
                  <div v-if="projectData.surfaceAreas.length">
                    <b-alert
                      v-for="item in projectData.surfaceAreas.slice(0, 4)"
                      :key="item.id"
                      class="m-0 mb-50"
                      variant="info"
                      show
                    >
                      <div class="alert-body d-flex justify-content-between">
                        <span>{{ item.area_category.name }}</span>
                        {{ item.surface_area }}m2
                      </div>
                    </b-alert>
                  </div>
                  <div
                    v-else
                    class="h-100"
                  >
                    <small class="text-muted">
                      Luas Area
                    </small>
                    <h6 class="text-muted mt-50 font-weight-normal">
                      Luas area belum diisi.
                      <span
                        class="text-primary cursor-pointer font-weight-bold"
                        @click="projectData.isAssigned || $root.role === 2 ? $router.push({ name: 'project-edit', params: { id: id }}) : $router.push('/unauthorized')"
                      >
                        Isi disini
                      </span>
                    </h6>
                  </div>
                  <b-alert
                    v-if="projectData.surfaceAreas.length > 4"
                    v-b-popover.hover.top="projectData.surfaceAreas.slice(4, projectData.surfaceAreas.length).map(el => `${el.area_category.name} (${el.surface_area}m2)`).join(', ')"
                    class="m-0 mb-50"
                    variant="light"
                    show
                  >
                    <div class="alert-body">
                      <span>+ {{ projectData.surfaceAreas.length - 4 }} area lainnya</span>
                    </div>
                  </b-alert>
                </b-col>
                <b-col
                  cols="12"
                  lg="4"
                >
                  <project-performance :id="+id" />
                </b-col>
              </b-row>
            </b-card>
            <b-card>
              <project-chart
                :project-id="+id"
              />
            </b-card>
            <b-card>
              <b-tabs>
                <b-tab title="Quotes">
                  <b-overlay :show="loadingQuotes">
                    <quotations-table
                      :data="quoteList"
                      :count="quoteList.length"
                      @refetch="fetchQuote"
                    />
                  </b-overlay>
                </b-tab>
                <b-tab title="Dry Report">
                  <b-overlay :show="loadingDryReports">
                    <dry-report-table
                      :data="dryReports"
                      :count="dryReports.length"
                      @refetch="fetchDryReports"
                    />
                  </b-overlay>
                </b-tab>
                <template #tabs-end>
                  <b-dropdown
                    v-if="projectData.isAssigned || $root.role === 2"
                    variant="link"
                    toggle-class="text-decoration-none"
                    no-caret
                    offset="-100"
                    boundary="window"
                    class="ml-auto"
                  >
                    <template v-slot:button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="text-body align-middle mr-25"
                      />
                    </template>
                    <b-dropdown-item
                      v-if="projectData.isAssigned || $root.role === 2"
                      :to="{ name: 'addquote', params: { selectedCustomer: projectData.customer, selectedProject: projectData,resetFormState: true } }"
                    >
                      <feather-icon
                        icon="ClipboardIcon"
                        class="mr-50 mb-25 text-primary"
                      />
                      <span class="text-primary">Buat Quote</span>
                    </b-dropdown-item>
                    <b-dropdown-item
                      v-if="projectData.isAssigned || $root.role === 2"
                      :to="{ name: 'adddryreport' }"
                    >
                      <feather-icon
                        icon="FileTextIcon"
                        class="mr-50 mb-25 text-info"
                      />
                      <span class="text-info">Buat Dry Report</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-tabs>
            </b-card>
          </div>
        </XyzTransition>
      </div>
    </div>
    <error-endpoint
      v-else
      :key="error"
      ref="error"
      :error="error"
    />
  </div>
</template>

<script>
import BackNavigation from '@/components/misc/BackNavigation.vue'
import ErrorEndpoint from '@/components/misc/ErrorEndpoint.vue'
import {
  BSpinner, BCard, BRow, BCol, BBadge, BAvatar, BTabs, BTab, BAlert, BButton, VBPopover, BOverlay, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import { avatarText, formatDate } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import QuotationsTable from '@/views/quotation/QuotationsTable.vue'
import DryReportTable from '@/views/dryreport/DryReportTable.vue'
import ProjectPerformance from './ProjectPerformance.vue'
import ProjectChart from './ProjectChart.vue'

export default {
  components: {
    BackNavigation,
    ErrorEndpoint,
    BSpinner,
    BCard,
    BRow,
    BCol,
    BBadge,
    BAvatar,
    ProjectPerformance,
    BTabs,
    BTab,
    BAlert,
    BButton,
    BOverlay,
    QuotationsTable,
    ProjectChart,
    DryReportTable,
    BDropdown,
    BDropdownItem,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    id: {
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {
      loading: true,
      loadingQuotes: true,
      loadingDryReports: true,
      projectData: '',
      avatarText,
      formatDate,
      error: '',
      quoteList: [],
      dryReports: [],
    }
  },
  computed: {
    ...mapGetters({
      permission: 'getUserPermission',
    }),
  },
  mounted() {
    this.getProjectDetail(Number(this.id)).then(result => {
      this.projectData = result.projectDetail
      this.loading = false
    }).catch(error => {
      this.loading = 'error'
      this.error = `${error.graphQLErrors[0].message}`
    })

    this.fetchQuote()
    this.fetchDryReports()
  },
  methods: {
    ...mapActions(['getProjectDetail', 'deleteProject', 'restoreProject']),
    fetchQuote() {
      this.loadingQuotes = true
      this.$store.dispatch('getQuotes', {
        promise: true,
        filter: `{
          project_id: [${+this.id}]
        }`,
        pagination: null,
      }).then(data => {
        this.loadingQuotes = false
        this.quoteList = data
      }).catch(() => {
        this.loadingQuotes = false
      })
    },
    fetchDryReports() {
      this.loadingDryReports = true
      this.$store.dispatch('getDryReports', {
        filter: {
          project_id: +this.id,
        },
        pagination: null,
      }).then(result => {
        this.dryReports = result.dry_reports
        this.loadingDryReports = false
      }).catch(() => {
        this.loadingDryReports = false
      })
    },
    deleteProjectConfirm() {
      this.$swal({
        title: 'Hapus Data Project?',
        text: 'Konfirmasi jika anda ingin menghapus data project',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteProject(+this.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menghapus data project',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'projects' })
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal menghapus data',
                icon: 'TrashIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    confirmRestore() {
      this.$swal({
        title: 'Restore Data Project?',
        text: 'Konfirmasi jika anda ingin restore data project',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.restoreProject(+this.id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil restore data project',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
            this.$router.push({ name: 'projects' })

            this.$emit('projectDeleted')
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal restore data',
                icon: 'TrashIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
